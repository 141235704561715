<template>
  <div>
    <div class="subBan"><img src="../../../public/pc/images/banner-news.jpg"></div>
    <div class="subNav wid">
      <div class="subTypeName">Company news</div>
      <div class="subTypeName tt">公司新闻</div>
      <div class="clr"></div>
      <center><dl></dl></center>
      <div style="background:#c6c6c6;margin:20px 0 10px;width:100%;height:1px"></div>
    </div>
    <div class="typename wid"><p>{{ data.name }}</p></div>
    <div class="jBoxTime wid">发布时间: {{ data.create_time }} &nbsp;&nbsp; </div>
    <div class="text wid" id="clear">
      <div class="editor-content-view" v-html="data.content"></div>
    </div>
    <center><a href="javascript:;" @click="$router.go(-1)" class="back">返回</a></center>
<!--    <ul class="anniu wid" id="clear">

      <li>下一篇：<a href="index-content_index_show_62_250.html">关于绍兴冠磊新材料有限公司年产1000万平方米包装材料项目环评的公示</a></li>

      <li>上一篇：<a href="javascript:alert('最后一页');">最后一页</a></li>

    </ul>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.getData(id)
  },
  methods: {
    getData(id) {
      this.$api.getNewsDetail({id}).then(res => {
        this.data = res.data
      })
    },
  },
}
</script>
